import {
  get,
  post,
  Delete,
} from '@/api/config'


export const NewsQuery = query => get('v1/bulletin/list' + query);
export const NoticeUpdate = (params) => post(`v1/bulletin/update`, params);

export const NoticeRemove = params => Delete(`v1/bulletin/delete`, params);


export const NoticeCreate = params => post('v1/bulletin/create', params);


export const imgQuery = query => get('v1/banner/list' + query);
// 轮播图删除
export const BannerRemove = params => Delete(`v1/banner/delete`, params);
// 创建轮播图
export const BannerCreate = params => post('v1/banner/create', params);
// 更新轮播图
export const BannerUpdate = (params) => post(`v1/banner/update`, params);
// 轮播图上架
export const BannerOnline = (params) => post(`v1/banner/enable`, params);

export const PosterQuery = query => get('v1/poster/list' + query);
// 轮播图删除
export const PosterRemove = params => Delete(`v1/poster/delete`, params);
// 创建轮播图
export const PosterCreate = params => post('v1/poster/create', params);
// 更新轮播图
export const PosterUpdate = (params) => post(`v1/poster/update`, params);
// 轮播图上架
export const PosterOnline = (params) => post(`v1/poster/enable`, params);

  // export const NoticeUpdate = params => put(`admin/notice/${params.id}`, params);
