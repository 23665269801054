<template>
  <div class="wrap">
    <header class="x-page-title">
      <h3>新闻/动态详情</h3>
    </header>

    <main class="x-main">
      <!-- 标题类型 -->
      <el-card class="mb30">
        <el-form
          class="x-form"
          ref="titleForm"
          size="small"
          label-width="140px"
          :model="form"
        >
          <el-form-item label="标题：" prop="title">
            <el-input
              :disabled="true"
              placeholder="请输入标题"
              maxlength="30"
              v-model="form.title"
            ></el-input>
          </el-form-item>

          <el-form-item label="类型：" prop="type">
            <el-select
              v-model="form.classification"
              placeholder="请选择分类"
              :disabled="true"
            >
              <el-option label="动态" value="1"></el-option>
              <el-option label="新闻" value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="编辑正文：" prop="content">
            <el-input
              :disabled="true"
              type="textarea"
              :rows="15"
              v-model="form.content"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-row type="flex" justify="center" class="mt10">
          <el-col :span="6">
            <el-button @click="goBack">返回上级页面</el-button>
          </el-col>
        </el-row>
      </el-card>
    </main>
  </div>
</template>

<script>
import { NoticeDetail } from "@/api/module/notice";

export default {
  name: "NoticeDetail",
  props: ["id"],
  data() {
    return {
      form: {
        classification: "",
        title: "",
        content: "",
      },
    };
  },
  created() {
    this.doQuery();
  },
  methods: {
    // 获取详情，绑定数据
    async doQuery() {
      const r = await NoticeDetail(this.id);
      this.form = {
        classification: String(r.classification),
        title: r.title,
        content: r.content,
      };
    },
    // 返回上级页面
    goBack() {
      this.$router.push(`/notice?tab=first`);
    },
  },
};
</script>